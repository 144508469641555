<template>
  <va-card title="Update Dynamic Environment (Legacy)">
    <va-input
      label="UAN (Unique ID)"
      v-model="CPE_UAN"
      placeholder="ex: 5U4J9R2350-463GF-54576YH"
    >
      <va-icon
        slot="prepend"
        color="gray"
        name="fa fa-vcard"
      />
    </va-input>

    <va-button
      class="justify-center"
      :disabled="!allFilledOut"
      @click="submit"
    >
     Save Data
    </va-button>
  </va-card>
</template>

<script>
import firebase from 'firebase';

export default {
  data() {
    return {
      CPE_UAN: '',
    };
  },
   created() {
      //get
      firebase.firestore().collection('GlobalEnviromnent').doc('admin').get().then((doc) => {
        if (doc?.data()) {
          this.CPE_UAN = doc.data().CPE_UAN;
        }        
      });
  },
  methods: {
    submit() {
      this.$db.collection('GlobalEnviromnent').doc('admin').update({
        CPE_UAN: this.CPE_UAN
      }).then((snapshot)=>{
        this.$swal.fire ({
          title: 'Success',
          text: 'Data has been updated',
          type: 'success',
          confirmButtonText: 'OK',
        });
      });
    },
  },
  computed: {
    allFilledOut() {
      if(this.CPE_UAN.length > 0) {
        return true;
      }
    },
  },
};
</script>

<style>

</style>
